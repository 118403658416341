import { getToken } from "../context/Auth/Storage";

const testURL = "https://customer.dev.easerp.com/api/v1";
const prodURL = "https://api.orders.easerp.com/api/v1";

const staging = false;

const baseURL = staging ? testURL : prodURL;

export class AuthorizationError extends Error {
  constructor(message) {
    super(message);
    this.name = "AuthorizationError";
  }
}

export const getRequest = async (path, auth = true) => {
  const url = `${baseURL}/${path}`;

  const headers = new Headers();
  headers.append("Accept", "application/json");

  if (auth) {
    const token = getToken();
    headers.append("Authorization", `Bearer ${token}`);
  }

  const response = await fetch(url, {
    headers,
  });

  const status = response.status;
  if (status >= 200 && status < 400) {
    return await response.json();
  } else {
    if (status === 401) {
      throw new AuthorizationError("Unauthorized");
    } else {
      throw Error(
        response.message
          ? response.message
          : "Encountered an error while requesting data."
      );
    }
  }
};

export const postRequest = async (path, params, auth = true) => {
  const url = `${baseURL}/${path}`;

  const headers = new Headers();
  headers.append("Accept", "*/*");
  headers.append("Content-Type", "application/json");

  if (auth) {
    const token = getToken();
    headers.append("Authorization", `Bearer ${token}`);
  }

  const response = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(params),
  });

  const status = response.status;
  const json = await response.json();

  return { status, json };
};

export const patchRequest = async (path, params, auth = true) => {
  const url = `${baseURL}/${path}`;

  const headers = new Headers();
  headers.append("Accept", "*/*");
  headers.append("Content-Type", "application/json");

  if (auth) {
    const token = getToken();
    headers.append("Authorization", `Bearer ${token}`);
  }

  const response = await fetch(url, {
    method: "PATCH",
    headers,
    body: JSON.stringify(params),
  });

  const status = response.status;
  const json = await response.json();

  return { status, json };
};
